@charset "UTF-8";
/* 余白 8の倍数 */
/*
page
*/
#page .page-header .jumbotron {
  background-color: #004891;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin-bottom: 0;
  min-height: 220px;
  overflow: hidden;
  position: relative;
}
@media not all and (min-width: 768px) {
  #page .page-header .jumbotron {
    min-height: 170px;
  }
}
@media not all and (min-width: 600px) {
  #page .page-header .jumbotron {
    min-height: 80px;
  }
}
#page .page-header .jumbotron::before {
  background: url(../img/page/page_header_img.svg) no-repeat center center;
  background-size: 100%;
  content: "";
  display: block;
  width: 232px;
  height: 217px;
  position: absolute;
  bottom: 0;
  left: 4vw;
}
@media not all and (min-width: 992px) {
  #page .page-header .jumbotron::before {
    width: 180px;
    height: 168px;
  }
}
@media not all and (min-width: 768px) {
  #page .page-header .jumbotron::before {
    width: 171px;
    height: 160px;
    left: 20px;
  }
}
@media not all and (min-width: 600px) {
  #page .page-header .jumbotron::before {
    width: 75px;
    height: 70px;
    left: 15px;
  }
}
#page .page-header .modTitle {
  padding: 15px;
  margin: 0 auto;
  text-align: left;
  width: 1200px;
  max-width: 100%;
}
@media not all and (min-width: 768px) {
  #page .page-header .modTitle {
    text-align: center;
  }
}
#page .page-header .modTitle__ja {
  color: white;
  font-size: calc(22px + 10 * (100vw - 320px) / 1600);
}
#page .page-sec-inner {
  padding: 50px 0 70px;
}

.mocCardBlock__card .mocCardBlock__link {
  display: block;
}