@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

/*
page
*/

#page {
  .page-header {

    .jumbotron {
      background-color: variables.$COLOR_MAIN;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin-bottom: 0;
      min-height: 220px;
      overflow: hidden;
      position: relative;

      @include mixin.tabletP {
        min-height: 170px;
      }

      @include mixin.sp {
        min-height: 80px;
      }

      &::before {
        background: url(#{mixin.set_image_path('page/page_header_img.svg')}) no-repeat center center;
        background-size: 100%;
        content: "";
        display: block;
        width: 232px;
        height: 217px;
        position: absolute;
        bottom: 0;
        left: 4vw;

        @include mixin.tabletL {
          width: 180px;
          height: 168px;
        }

        @include mixin.tabletP {
          width: 171px;
          height: 160px;
          left: 20px;
        }

        @include mixin.sp {
          width: 75px;
          height: 70px;
          left: 15px;
        }
      }
    }

    .modTitle {
      padding: 15px;
      margin: 0 auto;
      text-align: left;
      width: 1200px;
      max-width: 100%;

      @include mixin.tabletP {
        text-align: center;
      }

      &__ja {
        color: white;
        font-size: calc(22px + 10 * (100vw - 320px) / 1600);
      }
    }
  }

  .page-sec-inner {
    padding: 50px 0 70px;
  }
}

.mocCardBlock__card {
  .mocCardBlock__link {
    display: block;
  }
}